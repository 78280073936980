/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

* {
    box-sizing: border-box;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}
body {
    line-height: 1;
    background-color: #F8F8F8;
    font-family: "Roboto", sans-serif;
}
ol, ul {
    list-style: none;
}
blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}

h1,h2,h3,h4,h5 {
    font-weight: bold;

    &.color-secondary {
        color: #BB317F;
    }
}

h1 {
    font-size: 56px;

    @media (max-width:480px)  {
        font-size: 48px;
    }
}

h2 {
    font-size: 48px;

    @media (max-width:480px)  {
        font-size: 32px;
    }
}

h3 {
    font-size: 32px;

    @media (max-width:480px)  {
        font-size: 24px;
    }
}

h4 {
    font-size: 24px;

    @media (max-width:480px)  {
        font-size: 16px;
    }
}

h5 {
    font-size: 16px;
}

strong {
    font-weight: bold;
}

p {
    line-height: 1.5;

    &.text-center {
        text-align: center;
    }
}

.roboto-thin {
    font-family: "Roboto", sans-serif;
    font-weight: 100;
    font-style: normal;
}

.roboto-light {
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-style: normal;
}

.roboto-regular {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.roboto-medium {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-style: normal;
}

.roboto-bold {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-style: normal;
}

.roboto-black {
    font-family: "Roboto", sans-serif;
    font-weight: 900;
    font-style: normal;
}

.roboto-thin-italic {
    font-family: "Roboto", sans-serif;
    font-weight: 100;
    font-style: italic;
}

.roboto-light-italic {
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-style: italic;
}

.roboto-regular-italic {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: italic;
}

.roboto-medium-italic {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-style: italic;
}

.roboto-bold-italic {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-style: italic;
}

.roboto-black-italic {
    font-family: "Roboto", sans-serif;
    font-weight: 900;
    font-style: italic;
}

.orange {
    color: #F14F28;
}

.pink {
    color: #BB317F;
}

.disabled-link {
    pointer-events: none;
    cursor: default;
    text-decoration: none;
    color: grey !important;
}

.btn-secondary.disabled-link {
    background-color: grey !important;
    color: white !important;
}

.disabled-button {
    pointer-events: none;
    cursor: default;
    background-color: grey !important;
    color: white !important;
}
